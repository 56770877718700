.produtos-slider {
    height: 100%;
    position: relative;
}

.slider-arrow {
    font-size: 1.5em;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 10px 15px;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}

.slider-arrow-left {
    /* left: 10px; */
    left: 0px;
}

.slider-arrow-right {
    /* right: 10px; */
    right: 0px;
}

.slider-images {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.slider-buttons {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    width: 100%;
}

.slider-buttons button {
    position: relative;
    top: 2px;
    margin: 0 10px;
    font-size: 1.1em;
    color: #d3d3d3;
}

.slider-buttons button:first-child {
    margin-left: 0;
}

.slider-buttons button:last-child {
    margin-right: 0;
}

.slider-button-active {
    color: #2faf5b !important;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .slider-buttons {
        padding: 5px 0;
    }
    
    .slider-buttons button {
        font-size: 2em;
        top: -3px;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .slider-arrow {
        font-size: 1.6em;
        padding: 7px 15px 10px 15px;
    }

    .slider-buttons {
        padding: 0 0;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .slider-arrow {
        padding: 9px 15px 13px 15px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .slider-arrow {
        font-size: 3em;
        padding: 10px 20px 15px 20px;
    }
        
    .slider-buttons {
        padding: 10px 0;
    }

    .slider-buttons button {
        font-size: 3em;
        margin: 0 15px;
    }

}