.servicos-content {
    padding: 20px;
    line-height: 1.5;
}

.servicos-content h2 {
    text-align: center;
    font-size: 1em;
}

.servicos-content p {
    text-indent: 20px;
    font-weight: 300;
}

.servicos-summary {
    margin-bottom: 25px;
}

.servicos-atendimento {
    margin-bottom: 40px;
}

.servicos-atendimento h2 {
    font-weight: 400;
    font-size: 1.05em;
    margin-bottom: 10px;
}

.servicos-atendimento p span {
    font-weight: 400;
}

.atendimento-info {
    margin-top: 20px;
}

.atendimento-info p {
    text-indent: 0;
}

.atendimento-info p span {
    font-weight: 300;
    font-style: italic;
}

.atendimento-info h3 {
    font-size: 1em;
    font-weight: 400;
}

.atendimento-info h3:nth-of-type(2) {
    margin-top: 15px;
}

.atendimento-image {
    margin-top: 30px;
    text-align: center;
}

.atendimento-image img {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.atendimento-image h4 {
    font-size: 0.95em;
    font-weight: 300;
    font-style: italic;
    color: #576366;
    line-height: 1.3;
}

.servicos-feiras {
    /* outline: 1px solid blue; */
    margin-bottom: 40px;
}

.servicos-feiras h2 {
    font-weight: 400;
    font-size: 1.05em;
    margin-bottom: 10px;
}

.servicos-feiras-image {
    text-align: center;
    margin: 20px 0;
}

.servicos-feiras-image img {
    width: 70px;
    opacity: 0.9;
}

.feiras-container {
    /* outline: 1px solid red; */
    scroll-margin-top: 75px;
}

.feiras-container p span {
    font-weight: 400;
}

.feiras-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.feiras-button {
    width: 100%;
    background-color: #63796c;
    padding: 15px 0;
    color: #fff;
    font-size: 0.95em;
    border-radius: 10px;
    margin-bottom: 5px;
    transition: background-color 0.4s;
}

.feiras-button:last-child {
    margin-bottom: 0;
}

.feiras-button-active {
    background-color: #2faf5b !important;
}

.feiras {
    display: none;
    scroll-margin-top: 75px;
    margin-top: 40px;
    /* outline: 1px solid blue; */
}

.display-feiras {
    display: block;
}

.feiras-current {
    text-align: center;
}

.feiras-current p {
    text-indent: 0;
    line-height: 1.3;
    font-size: 0.9em;
    font-style: italic;
}

.feiras-current p span {
    font-weight: 400;
}

.feiras-content h2 {
    font-size: 1.1em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 25px 0;
}

.feiras-service-hours {
    margin-bottom: 12px;
}

.feiras-address {
    margin-bottom: 20px;
}

.feiras-service-hours h3,
.feiras-address h3 {
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 2px;
}

.feiras-service-hours p,
.feiras-address p {
    font-size: 0.95em;
    text-indent: 0;
}

.feira-map {
    height: 300px;
    width: 100%;
    outline: 1px solid rgba(0,0,0,0.1);
}

.close-feiras {
    /* outline: 1px solid red; */
    margin-top: 22px;
    text-align: center;
}

.close-feiras button {
    font-size: 0.95em;
    padding: 5px 20px;
    text-decoration: underline;
}

/* .servicos-responsabilidade {
    outline: 1px solid red;
} */

.servicos-responsabilidade h2 {
    font-weight: 400;
    font-size: 1.05em;
    margin-bottom: 10px;
}

.responsabilidade-image {
    text-align: center;
    margin: 20px 0;
}

.responsabilidade-image img {
    width: 100%;
}

.responsabilidade-image h4 {
    font-size: 0.95em;
    font-weight: 300;
    font-style: italic;
    color: #576366;
    line-height: 1.3;
}

.responsabilidade-symbol {
    text-align: center;
    margin: 30px 0 20px 0;
}

.responsabilidade-symbol img {
    width: 70px;
}

.responsabilidade-social-italic {
    font-style: italic;
}

.parceiros-container {
    margin: 20px 0 50px 0;
    padding: 30px 0;
    border-top: 1px solid #cfd9e0;
    border-bottom: 1px solid #cfd9e0;
    background-color: rgb(207, 217, 224, 0.2);
    position: relative;
}

.parceiros-container::after {
    content: '';
    position: absolute;
    background-image: url('../../assets/images/icon-bookmark.png');
    width: 34px;
    height: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    right: 10px;
}

.parceiros-summary {
    line-height: 1.5;
    padding: 0 25px;
}

.parceiros-summary h2 {
    font-size: 1.1em;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px; 
}

.parceiros-summary p {
    font-weight: 300;
    text-indent: 20px;
}

.parceiros-button-wrapper {
    text-align: center;
    margin-top: 25px;
}

.parceiros-button-wrapper button {
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
    }
    70% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.95);
    }
}

.parceiros-button-wrapper button img {
    width: 58px;
}

.parceiros-content {
    display: none;
    margin-top: 30px;
}

.display-parceiros-content {
    display: block;
}

.parceiros-image-wrapper {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    left: -100px;
    opacity: 0;
}

.parceiros-image-wrapper:last-child {
    margin-bottom: 0;
}

.parceiros-image-effect {
    opacity: 1;
    left: 0;
    transition: all 0.6s;
}

.parceiros-image {
    max-height: 300px;
    width: 250px; /* 300px */
    object-fit: contain;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .servicos-content {
        padding: 20px 30px;
    }

    .servicos-content h2 {
        font-size: 1.1em;
    }

    .atendimento-image img {
        height: 220px;
    }

    .feiras-buttons {
        width: 80%;
        margin: 20px auto 0 auto;
    }

    .feiras-button {
        font-size: 1em;
        margin-bottom: 8px;
    }

    .parceiros-summary {
        padding: 0 50px;
    }

    .parceiros-summary h2 {
        font-size: 1.2em;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .servicos-content {
        padding: 40px 60px;
        line-height: 1.6;
    }

    .servicos-content h2 {
        font-size: 1.15em;
    }

    .servicos-content p {
        text-indent: 40px;
        font-size: 0.95em;
    }

    .servicos-summary {
        margin-bottom: 35px;
    }

    .servicos-atendimento {
        margin-bottom: 50px;
    }

    .servicos-atendimento h2 {
        margin-bottom: 15px;
    }

    /* spans: ATENDIMENTO E ÁREAS DE ATUAÇÃO - desktop */
    .servicos-atendimento p span {
        font-size: 0.99em;
    }
    
    .atendimento-info p {
        text-indent: 0;
    }

    .atendimento-image img {
        width: 75%;
    }

    .atendimento-image h4 {
        font-size: 0.9em;
    }

    .servicos-feiras {
        margin-bottom: 60px;
    }

    .feiras-container {
        scroll-margin-top: 85px;
    }

    /* span: três vezes por semana - desktop */
    .feiras-container p span {
        font-size: 0.99em;
    }

    .feiras-buttons {
        width: 280px;
    }

    .feiras-button {
        font-size: 0.95em;
        font-weight: 300;
        margin-bottom: 6px;
    }

    .feiras-button:hover {
        background-color: rgba(99, 121, 108, 0.85);
    }

    .feiras {
        scroll-margin-top: 85px;
        margin-top: 60px;
    }

    .feiras-content h2 {
        font-size: 1.2em;
        margin: 35px 0;
    }

    .feiras-current p,
    .feiras-service-hours p,
    .feiras-address p {
        text-indent: 0;
    }

    .feira-map {
        height: 400px;
    }

    .close-feiras {
        margin-top: 30px;
    }

    .close-feiras button {
        font-size: 0.9em;
    }

    .close-feiras button:hover {
        text-decoration: none;
    }

    .responsabilidade-image {
        margin: 30px 0;
    }

    .responsabilidade-image img {
        width: 70%;
    }

    .responsabilidade-image h4 {
        font-size: 0.9em;
    }

    .responsabilidade-symbol {
        margin: 25px 0 10px 0;
    }

    .parceiros-container {
        margin: 80px 0;
        padding: 40px 0;
    }

    .parceiros-container::after {
        right: 70px;
        width: 40px;
        height: 40px;
    }

    .parceiros-summary {
        line-height: 1.6;
        width: 65%;
        margin: 0 auto;
        padding: 0;
    }

    .parceiros-summary p {
        font-size: 0.95em;
        text-indent: 40px;
    }

    .parceiros-button-wrapper button img {
        width: 50px;
    }

    .parceiros-image {
        width: 300px;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .servicos-content {
        padding: 45px 100px;
    }

    .servicos-content p {
        text-indent: 50px;;
    }

    .servicos-summary {
        margin-bottom: 45px;
    }

    .atendimento-info p {
        text-indent: 0;
    }

    .atendimento-image {
        margin-top: 40px;
    }

    .atendimento-image img {
        width: 550px;
        height: 280px;
    }

    .servicos-feiras-image img {
        opacity: 1;
        margin: 15px 0 25px 0;
    }

    .feiras-buttons {
        margin-top: 30px;
    }    

    .feiras-current p,
    .feiras-service-hours p,
    .feiras-address p {
        text-indent: 0;
    }

    .feira-map {
        height: 500px;
    }

    .responsabilidade-image img {
        width: 600px;
    }

    .responsabilidade-symbol {
        margin: 45px 0 10px 0;
    }

    .parceiros-container::after {
        right: 90px;
    }

    .parceiros-summary {
        width: 800px;
    }

    .parceiros-summary p {
        text-indent: 50px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .servicos-content {
        padding: 80px 120px;
    }

    .servicos-content h2 {
        font-size: 2.4em;
    }

    .servicos-content p {
        font-size: 2.1em;
        text-indent: 80px;
    }

    .servicos-summary {
        margin-bottom: 60px;
    }

    .servicos-atendimento {
        margin-bottom: 80px;
    }

    /* spans: ATENDIMENTO E ÁREAS DE ATUAÇÃO - desktop */
    .servicos-atendimento p span {
        font-size: 1em;
    }

    .atendimento-info {
        margin-top: 40px;
    }

    .atendimento-info h3 {
        font-size: 2.1em;
    }

    .atendimento-info p {
        text-indent: 0;
    }

    .atendimento-image {
        margin-top: 60px;
    }

    .atendimento-image img {
        width: 1000px;
        height: 600px;
    }

    .atendimento-image h4 {
        font-size: 2em;
    }

    .servicos-feiras {
        margin-bottom: 100px;
    }

    .servicos-feiras-image img {
        width: 128px;
    }

    .feiras-container {
        scroll-margin-top: 135px;
    }

    .feiras-buttons {
        width: 550px;
    }

    .feiras-button {
        font-size: 2.1em;
        padding: 30px 0;
        margin-bottom: 12px;
    }

    .feiras {
        margin-top: 100px;
        scroll-margin-top: 135px;
    }

    .feiras-current p {
        text-indent: 0;
        font-size: 2em;
    }

    .feiras-content h2 {
        font-size: 2.6em;
        margin: 60px 0;
    }

    .feiras-service-hours h3,
    .feiras-address h3 {
        font-size: 2.1em;
    }

    .feiras-service-hours p,
    .feiras-address p {
        text-indent: 0;
    }

    .feiras-address {
        margin-bottom: 40px;
    }

    .feira-map {
        height: 1000px;
    }

    .close-feiras {
        margin-top: 50px;
    }

    .close-feiras button {
        font-size: 2em;
    }

    .responsabilidade-image {
        margin: 60px 0;
    }

    .responsabilidade-image img {
        width: 1000px;
    }

    .responsabilidade-image h4 {
        font-size: 2em;
    }

    .responsabilidade-symbol {
        margin: 60px 0 20px 0;
    }

    .responsabilidade-symbol img {
        width: 128px;
    }

    .parceiros-container {
        margin: 180px 0;
        padding: 80px 0;
    }

    .parceiros-container::after {
        width: 80px;
        height: 80px;
        right: 200px;
    }

    .parceiros-summary {
        width: 50%;
    }

    .parceiros-summary h2 {
        font-size: 2.4em;
    }

    .parceiros-summary p {
        font-size: 2.1em;
        text-indent: 80px;
    }
    
    .parceiros-button-wrapper {
        margin-top: 45px;
    }

    .parceiros-button-wrapper button img {
        width: 110px;
    }

    .parceiros-content {
        margin-top: 80px;
    }

    .parceiros-image-wrapper {
        margin-bottom: 40px;
    }

    .parceiros-image {
        max-height: 600px;
        width: 600px;
    }
    
}