.sobre-content {
    padding: 20px;
}

.sobre-content p {
    font-weight: 300;
    line-height: 1.5;
    color: #576366;
}

.sobre-history p {
    text-indent: 20px;
}

.sobre-image-frutyl {
    position: relative;
    margin: 15px auto 10px auto;
}

.sobre-image-frutyl img {
    width: 100%;
}

.sobre-image-frutyl h3 {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 7px 5px;
    text-align: center;
    color: #fff;
    font-size: 0.9em;
    font-weight: 300;
}

.sobre-strategy {
    text-align: center;
    margin-top: 25px;
}

.sobre-strategy h2 {
    font-weight: 400;
    font-size: 1.05em;
    margin-bottom: 3px;
}

.sobre-strategy div {
    margin-bottom: 25px;
}

.sobre-strategy div:last-child {
    margin-bottom: 0;
}

.sobre-strategy p {
    padding: 0 10px;
}

.sobre-strategy ul li {
    line-height: 1.5;
    font-weight: 300;
    color: #576366;
}

.sobre-image-heart {
    text-align: center;
    margin: 30px 0 20px 0;
}

.sobre-image-heart img {
    width: 48px;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {



}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .sobre-content {
        padding: 30px 40px;
    }

    .sobre-history p {
        line-height: 1.6;
        font-size: 0.95em;
        text-indent: 40px;
    }

    .sobre-image-frutyl {
        width: 70%;
        margin: 25px auto;
    }
    
    .sobre-image-frutyl img {
        height: 300px;
    }

    .sobre-strategy {
        margin-top: 40px;
    }

    .sobre-strategy h2 {
        font-size: 1.1em;
    }

    .sobre-strategy div {
        margin-bottom: 30px;
    }

    .sobre-strategy p {
        line-height: 1.6;
        padding: 0 20px;
        font-size: 0.95em;
    }

    .sobre-strategy ul li {
        line-height: 1.6;
        font-size: 0.95em;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .sobre-content {
        padding: 50px 100px;
    }

    .sobre-image-frutyl {
        width: 55%;
    }

    .sobre-strategy h2 {
        margin-bottom: 4px;
    }

    .sobre-image-heart {
        margin: 35px 0 8px 0;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .sobre-content {
        padding: 100px 140px;
    }

    .sobre-content p {
        font-size: 2.1em;
    }

    .sobre-history p {
        text-indent: 80px;
    }

    .sobre-image-frutyl {
        width: 60%;
        margin: 60px auto;
    }

    .sobre-image-frutyl img {
        height: 600px;
    }

    .sobre-image-frutyl h3 {
        font-size: 2.1em;
        padding: 15px 0;
    }

    .sobre-strategy {
        margin-top: 100px;
    }

    .sobre-strategy h2 {
        font-size: 2.3em;
        margin-bottom: 7px;
    }

    .sobre-strategy div {
        margin-bottom: 40px;
    }

    .sobre-strategy ul li {
        font-size: 2.1em;
    }

    .sobre-image-heart {
        margin: 70px 0 0 0;
    }

    .sobre-image-heart img {
        width: 92px;
    }

}