.page-not-found-wrapper {
    background-color: #313131;
    position: fixed;
    text-align: center;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.page-not-found-content {
    width: 90%;
}

.page-not-found-content img {
    width: 80px;
    margin-bottom: 20px;
}

.page-not-found-content h1 {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 20px;
}

.page-not-found-content p {
    font-weight: 300;
    line-height: 1.5;
}

.page-not-found-content p:nth-of-type(2) {
    margin-bottom: 30px;
}

.page-not-found-content button {
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 1.1em;
    margin-bottom: 30px;
    color: #fff;
}

.page-not-found-copyright {
    margin-bottom: 0;
    font-size: 0.95em;
}

.page-not-found-copyright span {
    font-weight: 400;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .page-not-found-content h1 {
        font-size: 1.3em;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .page-not-found-content h1 {
        font-size: 1.1em;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .page-not-found-content h1 {
        font-size: 1.2em;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .page-not-found-content img {
        width: 200px;
        margin-bottom: 60px;
    }

    .page-not-found-content h1 {
        font-size: 3em;
        margin-bottom: 60px;
    }

    .page-not-found-content p {
        font-size: 2.5em;
    }

    .page-not-found-content p:nth-of-type(2) {
        margin-bottom: 80px;
    }

    .page-not-found-content button {
        font-size: 2.8em;
        margin-bottom: 80px;
    }

    .page-not-found-copyright {
        font-size: 2.3em !important;
    }

}