.beneficios-content {
    padding: 20px 20px;
    line-height: 1.5;
}

.beneficios-summary {
    margin-bottom: 40px;
}

.beneficios-summary p {
    font-weight: 300;
    text-indent: 20px;
}

.beneficios-summary-image {
    text-align: center;
    margin-top: 35px;
}

.beneficios-summary-image img {
    width: 80px;
}

.beneficio-banana {
    margin-bottom: 35px;
}

.beneficio-title {
    text-align: center;
    margin-bottom: 10px;
}

.beneficio-title h3 {
    font-size: 0.85em;
    font-weight: 300;
}

.beneficio-title h2 {
    font-size: 1.1em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.beneficio-banana p {
    font-weight: 300;
    text-indent: 20px;
}

.beneficio-banana p span {
    font-weight: 400;
}

.beneficios-footer-image {
    text-align: center;
    margin-bottom: 15px;
}

.beneficios-footer-image img {
    width: 64px;
    position: relative; 
    top: -5px;
    left: -5px;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .beneficios-content {
        padding: 30px 30px;
    }
    
}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .beneficios-content {
        padding: 40px 60px;
        line-height: 1.6;
    }

    .beneficios-summary {
        margin-bottom: 50px;
    }

    .beneficios-summary p {
        font-size: 0.95em;
        text-indent: 40px;
    }

    .beneficios-summary-image img {
        width: 72px;
    }

    .beneficio-banana {
        margin-bottom: 50px;
    }

    .beneficio-banana h3 {
        font-size: 0.8em;
        position: relative;
        top: 3px;
    }

    .beneficio-banana h2 {
        font-size: 1.05em;
        letter-spacing: 0;
    }

    .beneficio-banana p {
        font-size: 0.95em;
        text-indent: 40px;
    }
    
    /* spans: ao longo dos benefícios - desktop */
    .beneficio-banana p span {
        font-size: 0.99em;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .beneficios-content {
        padding: 50px 100px;
    }

    .beneficios-summary p {
        text-indent: 50px;
    }

    .beneficios-summary-image {
        margin-top: 50px;
    }

    .beneficio-banana p {
        text-indent: 50px;
    }

    .beneficios-footer-image img {
        width: 70px;
        left: -2px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .beneficios-content {
        padding: 80px 120px; 
    }

    .beneficios-summary {
        margin-bottom: 100px;
    }

    .beneficios-summary p {
        font-size: 2.1em;
        text-indent: 80px;
    }

    .beneficios-summary-image img {
        width: 120px;
    }

    .beneficio-banana {
        margin-bottom: 80px;
    }

    .beneficio-banana h3 {
        font-size: 2em;
    }

    .beneficio-banana h2 {
        font-size: 2.3em;
    }

    .beneficio-banana p {
        font-size: 2.1em;
        text-indent: 80px;
    }

    /* spans: ao longo dos benefícios - desktop */
    .beneficio-banana p span {
        font-size: 1em;
    }
    
    .beneficios-footer-image {
        margin-bottom: 25px;
    }

    .beneficios-footer-image img {
        width: 128px;
    }

}