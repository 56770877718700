.spotlight-container {
    margin: 20px 0 50px 0;
    border-top: 1px solid #cfd9e0;
    border-bottom: 1px solid #cfd9e0;
    background-color: rgb(207, 217, 224, 0.2);
    
}

.spotlight-container-wrapper {
    /* outline: 1px solid red; */
    display: grid;
    grid-template-areas: 'spotlight-title'
                         'spotlight-description';
}

.spotlight-title,
.spotlight-description {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.spotlight-title {
    grid-area: spotlight-title;
    padding: 20px;
}

.spotlight-title-wrapper {
    position: relative;
}

.spotlight-title-wrapper img {
    width: 100%;
}

.spotlight-title-wrapper div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spotlight-title-wrapper h2 {
    font-weight: 300;
    font-size: 0.85em;
    /* color: #7a7a7a; */
    color: #576366;
    letter-spacing: 2px;
    margin-bottom: 2px;
}

.spotlight-title-wrapper h1 {
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: 2px;
    position: relative;
}

.spotlight-description {
    grid-area: spotlight-description;
    width: 75%;
    margin: 0 auto;
    padding: 30px 0;
}

.spotlight-description img {
    width: 42px;
    margin-bottom: 20px;
}

.spotlight-description p {
    line-height: 1.5;
    /* color: #576366; */
    font-weight: 300;
    margin-bottom: 20px;
    white-space: pre-line;
}

.spotlight-description button {
    border: none;
    padding: 15px 25px;
    background-color: #2faf5b;
    color: #fff;
    font-size: 0.9em;
    outline: 2px solid #fff;
    outline-offset: -6px;
    transition: all 0.3s;
}

.spotlight-title::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 1px;
    background-color: #cfd9e0;
}

.spotlight-description::after {
    display: none;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .spotlight-title-wrapper img {
        width: 380px;
    }

    .spotlight-description {
        width: 65%;
    }

    .spotlight-description button {
        font-size: 1.05em;
    }
}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .spotlight-container {
        margin: 80px 0 90px 0;
    }

    .spotlight-container-wrapper {
        grid-template-areas: 'spotlight-title spotlight-description';
        grid-template-columns: repeat(2, 1fr);
        width: 70%;
        margin: 0 auto;
    }

    .spotlight-title {
        padding: 20px 0;
    }

    .spotlight-description {
        padding: 20px 40px;
        width: 100%;
    }

    .spotlight-description img {
        margin-bottom: 30px;
    }

    .spotlight-description p {
        font-size: 0.95em;
        margin-bottom: 30px;
    }

    .spotlight-description button {
        font-size: 0.85em;
    }

    .spotlight-description button:hover {
        outline-offset: 0px;
    }

    .spotlight-title::after {
        display: none;
    }

    .spotlight-description::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 85%;
        background-color: #cfd9e0;
    }
 
}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .spotlight-container {
        margin: 100px 0 110px 0;
    }

    .spotlight-container-wrapper {
        width: 950px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .spotlight-container {
        margin: 160px 0 170px 0;
    }

    .spotlight-container-wrapper {
        width: 1450px;
    }

    .spotlight-title-wrapper img {
        width: 760px;
    }

    .spotlight-title-wrapper h2 {
        font-size: 1.8em;
    }

    .spotlight-container-wrapper h1 {
        font-size: 2.5em;
    }

    .spotlight-description {
        padding: 20px 60px;
    }

    .spotlight-description img {
        width: 92px;
        margin-bottom: 50px;
    }

    .spotlight-description p {
        font-size: 2em;
        margin-bottom: 50px;
    }
    
    .spotlight-description button {
        font-size: 2em;
        padding: 25px 35px;
        outline: 4px solid #fff;
        outline-offset: -10px;
    }

}