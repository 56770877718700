@import url('https://fonts.cdnfonts.com/css/gotham-rounded');

* {
    padding: 0;
    margin: 0;
    outline: none;
    box-sizing: border-box;
}

html, body, #root {
    min-height: 100%;
    /* scroll-behavior: smooth; */
}

body, input, button, textarea {
    font-family: 'Gotham Rounded', Verdana, sans-serif;
}

a,
a:visited,
a:active {
    text-decoration: none;
    color: inherit;
}

li {
    list-style: none;
}

button {
    cursor: pointer;
    background: transparent;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

button:active {
    outline: none;
}

.App {
    color: #282728;
    min-height: 100vh; /*footer*/
    display: flex;
    flex-direction: column; 
    /* #7a7a7a; */
    /* #576366 */
    /* #2faf5b */
}

.main-container {
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: -30px;
    z-index: 2;
    border-top: 5px solid #2faf5b;
    box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
}

.main-title {
    background-color: #f9f9f9;
    text-align: center;
    padding: 15px 10px;
    border-bottom: 2px solid #e5e5e5;
    position: relative;
}

.main-title::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    width: 50%;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #2faf5b;
}

.main-title h1 {
    font-size: 1.2em;
    font-weight: 400;
}


/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .main-container {
        width: 70%;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .main-container {
        width: 60%;
        top: 0;
        margin-top: 60px;
    }

    .main-title::after {
        width: 30%;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .main-container {
        width: 1100px;
        margin-top: 80px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .main-container {
        width: 50%;
    }

    .main-title {
        padding: 30px 0;
    }

    .main-title h1 {
        font-size: 2.7em;
    }

    .main-title::after {
        width: 35%;
    }

}