.navbar-container {
    position: fixed;
    z-index: 50;
    width: 100%;
    background-color: transparent;
    height: 70px;
    display: grid;
    grid-template-areas: 'navbar-logo navbar-hamburger';
    justify-content: space-between;
    padding: 0px 20px;
    transition: background-color 0.8s;
}

.navbar-scrolled {
    background-color: #000;
}

.navbar-logo {
    grid-area: navbar-logo;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-logo img {
    width: 160px;
}

.navbar-hamburger {
    grid-area: navbar-hamburger;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px;
}

.navbar-hamburger img {
    width: 32px;
}

.navbar-mobile {
    display: none;
    position: fixed;
    z-index: 99;
    inset: 0;
    background-color: rgb(32, 32, 32);
}

.display-navbar-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
}

.navbar-mobile-menu {
    text-align: center;
}

.navbar-mobile-menu li {
    font-size: 1.3em;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 15px;
}

.navbar-mobile-menu li:last-child {
    margin-bottom: 0;
}

.navbar-mobile-dismiss {
    padding: 10px 0;
    font-size: 2em;
    color: #fff;
}

.navbar-desktop {
    grid-area: navbar-desktop;
    display: none;
}

.disable-scroll {
    overflow: hidden;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .navbar-container {
        padding: 0 30px;
    }

    .navbar-mobile-menu li {
        font-size: 1.5em;
        margin-bottom: 50px;
    }

    .navbar-mobile-dismiss {
        font-size: 2.5em;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .navbar-container {
        grid-template-areas: 'navbar-logo navbar-desktop';
        padding: 0 40px;
        /* outline: 1px solid blue; */
    }

    .navbar-logo img {
        width: 240px;
    }

    .navbar-hamburger {
        display: none;
    }

    .navbar-desktop {
        /* outline: 1px solid red; */
        display: block;
        height: 70px;
    }

    .navbar-desktop ul {
        /* background-color: gold; */
        display: flex;
        align-items: center;
        height: 100%;
    }

    .navbar-desktop ul li {
        /* background-color: green; */
        color: #fff;
        font-weight: 300;
        margin-left: 15px;
        padding: 10px;
        cursor: pointer;
    }

    .navbar-desktop ul li:hover {
       opacity: 0.85;
    }

    .navbar-desktop ul li:first-child {
        margin-left: 0;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .navbar-container {
        padding: 0 70px;
    }

    .navbar-logo img {
        width: 260px;
    }

    .navbar-desktop ul li {
        margin-left: 20px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .navbar-container {
        height: 120px;
        padding: 0 150px;
    }

    .navbar-logo img {
        width: 440px;
    }

    .navbar-desktop {
        height: 120px;
    }

    .navbar-desktop ul li {
        font-size: 2em;
        margin-left: 40px;
        padding: 20px;
    }

}