.produtos-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.produtos-table caption {
    background-color: #2faf5b;
    color: #fff;
    padding: 10px 10px;
    font-size: 1.05em;
    font-weight: 300;
}

.produtos-table td {
    padding: 10px 5px;
    border-bottom: 1px solid #cfd9e0;
    font-size: 0.95em;
}

.produtos-table tr td:last-child {
    font-weight: 300;
    text-align: right;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {



}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .produtos-table caption {
        font-size: 1em;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {



}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .produtos-table caption {
        padding: 20px 0;
        font-size: 2.2em;
    }
    
    .produtos-table td {
        padding: 15px 5px;
        font-size: 2em;
    }

}