.home-main {
    /* outline: 1px solid red; */
    width: 90%;
    margin: 30px auto 0 auto; /* top right bottom left */
}

.home-main h2 {
    text-align: center;
    font-size: 1.2em;
    font-weight: 300;
}

.cards {
    margin-top: 35px;
    display: grid;
    grid-template-areas: 'produtos-card'
                         'servicos-card'
                         'receitas-card'
                         'beneficios-card';
}

.cards div:nth-child(1) {
    grid-area: produtos-card;
}

.cards div:nth-child(2) {
    grid-area: servicos-card;
}

.cards div:nth-child(3) {
    grid-area: receitas-card;
}

.cards div:nth-child(4) {
    grid-area: beneficios-card;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .home-main {
        width: 80%;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .home-main {
        margin-top: 40px;
        width: 75%;
    }

    .cards {
        margin-top: 40px;
        grid-template-areas: 'produtos-card servicos-card'
                             'receitas-card beneficios-card';
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .home-main {
        width: 1300px;
    }

    .home-main h2 {
        font-size: 1.2em;
    }

    .cards {
        gap: 45px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .home-main {
        margin-top: 80px;
        width: 2300px;
    }

    .home-main h2 {
        font-size: 2.6em;
    }

    .cards {
        margin-top: 80px;
        gap: 55px;
    }

}