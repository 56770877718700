.contato-form {
    display: flex;
    flex-direction: column;
}

.contato-form div {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column
}

.contato-form div input,
.contato-form div textarea {
    font-size: 1em;
    font-weight: 300;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: border 0.4s;
}

.contato-form div input:focus,
.contato-form div textarea:focus {
    border: 1px solid #2faf5b;
}

/* .contato-form div input {
    
}

.contato-form div textarea {
    
} */

.contato-form button {
    border: none;
    background-color: #2faf5b;
    color: #fff;
    font-size: 1em;
    text-transform: uppercase;
    width: 100%;
    margin: 10px auto;
    padding: 20px 0;
    outline: 2px solid #fff;
    outline-offset: -6px;
    transition: all 0.3s;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .contato-form button {
        width: 70%;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .contato-form div input,
    .contato-form div textarea {
        font-size: 0.95em;
        padding: 12px;
    }
        
    .contato-form button {
        width: 250px;
        font-size: 0.95em;
        padding: 15px 0;
    }

    .contato-form button:hover {
        outline-offset: 0px;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    /* .contato-form button {
        width: 45%;
    } */

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .contato-form div label {
        font-size: 2.1em;
    }

    .contato-form div input,
    .contato-form div textarea {
        font-size: 2.1em;
        padding: 22px;
    }
        
    .contato-form button {
        width: 40%;
        font-size: 2.2em;
        font-weight: 300;
        padding: 25px 0px;
        outline: 4px solid #fff;
        outline-offset: -10px;
    }

}