.form-notification {
    display: none;
    position: fixed;
    width: 90%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.16);
    padding: 20px;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    text-align: center;
}

.display-form-notification {
    display: block;
}

.form-notification img {
    width: 64px;
    margin-bottom: 15px;
}

.form-notification h2 {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 15px;
}

.form-notification p {
    font-weight: 300;
    line-height: 1.5;
}

.form-notification button {
    padding: 10px;
    margin: 15px 0 5px 0;
    font-size: 0.95em;
    text-decoration: underline;
}

.contato-content {
    padding: 20px 0;
    line-height: 1.5;
}

.contato-summary,
.contato-form-wrapper {
    padding: 0 20px;
}

.contato-content p {
    text-indent: 20px;
    font-weight: 300;
}

.contato-summary-image {
    text-align: center;
    margin: 20px 0;
}

.contato-summary-image img {
    width: 50px;
}

.contato-additional-info {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 60px 0 10px 0;
    position: relative;
    padding: 60px 20px 0 20px;
}

.contato-additional-info::after {
    content: '';
    background: url('../../assets/images/icon-chat-boxes.png');
    background-size: contain;
    width: 64px;
    height: 64px;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
}

.additional-info-summary {
    margin-bottom: 25px;
    text-align: center;
}

.additional-info-email,
.additional-info-phone {
    margin-bottom: 10px;
}

.additional-info-email h3,
.additional-info-phone h3,
.additional-info-address h3 {
    font-size: 1em;
    font-weight: 400;
}

.additional-info-summary p,
.additional-info-email p,
.additional-info-phone p,
.additional-info-address p {
    text-indent: 0;
}

.additional-info-socials {
    margin-top: 30px;
    
}

.additional-info-socials p {
    text-indent: 0;
}

.additional-info-socials {
    text-align: center;
}

.socials-links {
    margin-top: 8px;
}

.socials-links a:first-of-type {
    margin-right: 12px;
}

.socials-links a img {
    width: 40px;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .form-notification {
        width: 510px;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .form-notification {
        width: 550px;
        padding: 30px;
    }
    
    .form-notification h2 {
        font-size: 1em;
    }
    
    .form-notification p {
        font-size: 0.95em;
        line-height: 1.6;
    }
    
    .form-notification button {
        font-size: 0.9em;
    }

    .contato-content {
        padding: 30px 0;
        line-height: 1.6;
    }
    
    .contato-summary,
    .contato-form-wrapper {
        padding: 0;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .contato-summary p {
        text-align: center;
        font-size: 0.95em;
        text-indent: 0;
    }
    
    .contato-summary-image img {
        width: 48px;
    }

    .contato-additional-info {
        padding: 60px 0 0 0;
    }
    
    .contato-additional-info::after {
        width: 60px;
        height: 60px;
        top: -30px;
    }
    
    .additional-info-summary,
    .additional-info-email,
    .additional-info-phone,
    .additional-info-address,
    .additional-info-socials {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    .additional-info-summary p,
    .additional-info-email p,
    .additional-info-phone p,
    .additional-info-address p,
    .additional-info-socials p {
        font-size: 0.95em;
    }

    .socials-links a:first-of-type {
        margin-right: 14px;
    }
    
    .socials-links a img {
        width: 35px;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .contato-content {
        padding: 45px 0;
    }

    .contato-summary,
    .contato-form-wrapper {
        width: 55%;
    }

    .contato-summary-image {
        margin: 35px 0;
    }

    .contato-additional-info {
        margin: 80px 0 0 0;
        padding-top: 70px;
    }

    .additional-info-summary,
    .additional-info-email,
    .additional-info-phone,
    .additional-info-address,
    .additional-info-socials {
        width: 55%;
    }

    .socials-links a img {
        width: 38px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .form-notification {
        width: 1100px;
        padding: 50px;
    }
    
    .form-notification img {
        width: 110px;
        margin-bottom: 35px;
    }

    .form-notification h2 {
        font-size: 2.3em;
        margin-bottom: 30px;
    }

    .form-notification p {
        font-size: 2.1em;
    }

    .form-notification button {
        font-size: 2.2em;
        margin: 30px 0 10px 0;
    }

    .contato-content {
        padding: 100px 0;
    }

    .contato-summary p {
        font-size: 2.1em;
    }

    .contato-summary-image {
        margin: 60px 0;
    }

    .contato-summary-image img {
        width: 120px;
    }

    .contato-additional-info {
        margin-top: 150px;
        padding-top: 140px;
    }

    .contato-additional-info::after {
        width: 120px;
        height: 120px;
        top: -60px;
    }

    .contato-additional-info p,
    .contato-additional-info h3 {
        font-size: 2.1em;
    }

    .additional-info-socials {
        margin-top: 60px;
    }

    .socials-links {
        margin-top: 15px;
    }

    .socials-links a:first-of-type {
        margin-right: 30px;
    }

    .socials-links a img {
        width: 80px;
    }

}