.main-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 5%;
    color: #fff;
    background-color: #000;
}

.main-banner-container h1 {
    font-size: 1.8em;
    font-weight: 400;
    margin-bottom: 20px;
}

.main-banner-container h2 {
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.4;
    white-space: pre-line;
}

.additional-information {
    margin-top: 20px;
    font-size: 1.1em;
}

.additional-information p:first-child {
    margin-bottom: 2px;
}

.additional-information p:last-child {
    font-weight: 300;
}

.additional-information p:last-child span::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #2faf5b;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .main-banner-container {
        padding: 0 60px;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .main-banner-container {
        padding: 0 120px;
    }
    
    .main-banner-container h1 {
        font-size: 2.5em;
        margin-bottom: 25px;
    }
    
    .main-banner-container h2 {
        font-size: 1.3em;
        line-height: 1.6;
    }
    
    .additional-information {
        margin-top: 25px;
    }
      
}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .main-banner-container h1 {
        font-size: 3em;
        margin-bottom: 35px;
    }
    
    .main-banner-container h2 {
        font-size: 1.4em;
    }
    
    .additional-information {
        margin-top: 35px;
    }

}


/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .main-banner-container h1 {
        font-size: 6em;
        margin-bottom: 70px;
    }

    .main-banner-container h2 {
        font-size: 3em;
    }

    .additional-information {
        margin-top: 70px;
        font-size: 2.8em;
    }

    .additional-information p:last-child span::before {
        width: 32px;
        height: 32px;
    }

}