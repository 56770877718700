.produtos-content {
    padding: 20px 0;
}

/* .produtos-summary {
    outline: 1px solid red;
} */

.produtos-summary p {
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 15px;
    padding: 0 20px;
    color: #576366;
}

.produtos-summary p span {
    font-weight: 400;
    color: #000;
}

.produtos-summary div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.produtos-summary div button {
    padding: 15px 0;
    color: #576366;
    font-size: 0.85em;
}

.button-banana-active {
    background-color: #2faf5b;
    color: #fff !important;
}

.produtos-bananas {
    /* outline: 1px solid blue; */
    margin: 35px 0;
    padding: 0 20px;
}

.produtos-banana {
    display: none;
}

.banana-active {
    display: block;
}

.produtos-banana h2 {
    font-weight: 400;
    font-size: 1.2em;
    margin-bottom: 10px;
    text-align: center;
}

.produtos-banana p {
    font-weight: 300;
    line-height: 1.5;
    text-indent: 20px;
    color: #576366;
}

.produtos-table-wrapper {
    margin: 35px 0 40px 0;
}

.slider-title {
    text-align: center;
    font-size: 1.05em;
    font-weight: 400;
    margin-bottom: 5px;
}

.slider-title span {
    font-weight: 300;
}

.container-slider {
    width: 100%;
    height: 280px;
}

.produtos-contact {
    text-align: center;
    padding: 0 20px;
}

.produtos-contact img {
    width: 48px;
    margin-bottom: 20px;
}

.produtos-contact p {
    line-height: 1.5;
    font-weight: 300;
    color: #576366;
}

.produtos-contact button {
    margin: 20px 0 10px 0;
    border: none;
    padding: 15px 25px;
    background-color: #2faf5b;
    color: #fff;
    font-size: 0.9em;
    outline: 2px solid #fff;
    outline-offset: -6px;
    transition: all 0.3s;
}

.produtos-additional-info {
    margin: 20px 0 50px 0;
}

.additional-info-title {
    text-align: center;
}

.additional-info-title h2 {
    font-weight: 400;
    font-size: 1.2em;
    margin-bottom: 4px;
}

.additional-info-title p {
    font-weight: 300;
    line-height: 1.5;
    color: #576366;
    padding: 0 25px;
    margin-bottom: 15px;
}

/* .additional-info {
    background-color: bisque;
} */

.additional-info button {
    background-color: #575757;
    width: 100%;
    padding: 20px 10px;
    border: none;
    color: #fff;
    font-size: 0.9em;
    transition: background-color 0.4s;
}

.additional-info div {
    background-color: transparent;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s;
}

.additional-info-active {
    overflow: visible;
}

.additional-info div p {
    padding: 15px 20px;
    font-weight: 300;
    text-indent: 20px;
    line-height: 1.5;
    color: #576366;
}

/* getting the first 'additional-info', which is the second div inside 'produtos-additional-info' */
.additional-info:nth-of-type(2) div p:nth-child(1) {
    padding-bottom: 0;
}

.additional-info:nth-of-type(2) div p:nth-child(2) {
    padding-top: 0;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

    .additional-info-title p {
        padding: 0 35px;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .produtos-content {
        padding: 30px 0;
    }

    .produtos-summary {
        width: 70%;
        margin: 0 auto;
    }

    .produtos-summary p {
        line-height: 1.6;
        font-size: 0.95em;
    }

    .produtos-bananas {
        margin: 50px 0 35px 0;
        padding: 0 40px;
    }

    .produtos-bananas h2 {
        margin-bottom: 13px;
    }

    .produtos-bananas p {
        line-height: 1.6;
        text-indent: 40px;
        font-size: 0.95em;
    }

    .produtos-table-wrapper {
        width: 70%;
        margin: 35px auto 45px auto;
    }

    .slider-title {
        font-size: 0.95em;
    }

    .container-slider {
        width: 85%;
        height: 320px;
        margin: 0 auto;
    }

    .produtos-contact {
        padding: 0 40px;
    }

    .produtos-contact img {
        width: 42px;
        margin-bottom: 30px;
    }

    .produtos-contact p {
        line-height: 1.6;
        font-size: 0.95em;
    }

    .produtos-contact button {
        font-size: 0.85em;
    }

    .produtos-contact button:hover {
        outline-offset: 0px;
    }

    .produtos-additional-info {
        width: 60%;
        margin: 50px auto;
    }

    .additional-info-title p {
        font-size: 0.95em;
    }

    .additional-info button {
        font-size: 0.85em;
    }

    .additional-info button:hover {
        opacity: 0.95;
    }

    .additional-info div p {
        font-size: 0.95em;
        text-indent: 40px;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .produtos-content {
        padding: 50px 0;
    }

    .produtos-summary {
        width: 50%;
    }

    .produtos-bananas {
        margin: 60px 0 35px 0;
        padding: 0 90px;
    }

    .produtos-bananas h2 {
        margin-bottom: 15px;
    }

    .produtos-table-wrapper {
        width: 450px;
        margin: 35px auto 50px auto;
    }

    .container-slider {
        width: 70%;
    }

    .produtos-contact img {
        width: 48px;
    }

    .produtos-contact button {
        padding: 15px 30px
    }

    .produtos-additional-info {
        width: 1100px;
        margin: 70px auto 50px auto;
    }

    .additional-info-title p {
        margin-bottom: 20px;
    }

    .additional-info button {
        padding: 22px 0;
    }

    .additional-info div p {
        padding: 20px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .produtos-content {
        padding: 60px 0;
    }

    .produtos-summary p {
        font-size: 2.3em;
        margin-bottom: 40px;
    }

    .produtos-summary div button {
        font-size: 2.1em;
        padding: 30px 0;
    }

    .produtos-bananas {
        margin: 120px 0 100px 0;
        padding: 0 120px;
    }

    .produtos-banana h2 {
        font-size: 2.4em;
    }
    
    .produtos-banana p {
        font-size: 2.1em;
        text-indent: 80px;
    }

    .produtos-table-wrapper {
        width: 750px;
        margin: 50px auto 70px auto;
    }

    .slider-title {
        font-size: 2.1em;
        margin-bottom: 10px;
    }

    .container-slider {
        width: 1100px;
        height: 600px;
    }

    .produtos-contact {
        padding: 0 120px;
    }

    .produtos-contact img {
        width: 92px;
    }

    .produtos-contact p {
        font-size: 2.1em;
    }

    .produtos-contact button {
        margin: 50px 0 20px 0;
        font-size: 2.1em;
        padding: 25px 45px;
        outline: 4px solid #fff;
        outline-offset: -10px;
    }

    .produtos-additional-info {
        width: 50%;
        margin: 100px auto;
    }

    .additional-info-title h2 {
        font-size: 2.4em;
        margin-bottom: 10px;
    }

    .additional-info-title p {
        font-size: 2.1em;
        padding: 0 120px;
        margin-bottom: 30px;
    }
    
    .additional-info button {
        font-size: 2.3em;
        padding: 35px 0;
    }

    .additional-info div p {
        font-size: 2.1em;
        padding: 40px;
        text-indent: 80px;
    }

}