.card-container {
    text-align: center;
    margin-bottom: 30px;
}

.card-container h1 {
    font-size: 1.1em;
    font-weight: 400;
}

.card-container span {
    color: #2faf5b;
}

.card-container img {
    max-width: 100%;
    margin: 2px 0 4px 0;
    outline: 2px solid #fff;
    outline-offset: -6px;
    transition: all 0.3s;
    cursor: pointer;
}

.card-container p {
    /* color: #576366; */
    font-weight: 300;
    padding: 0 5px;
    line-height: 1.4;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {



}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .card-container {
        margin-bottom: 0;
    }

    .card-container h1 {
        font-size: 1.05em;
    }

    .card-container p {
        font-size: 0.95em;
    }

    .card-container img {
        margin: 3px 0 3px 0;
    }

    .card-container img:hover {
        outline: 4px solid #fff;
        outline-offset: -15px;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {



}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .card-container h1 {
        font-size: 2.3em;
    }
    
    .card-container img {
        margin: 10px 0 10px 0;
        outline: 4px solid #fff;
        outline-offset: -20px;
    }

    .card-container img:hover {
        outline: 8px solid #fff;
        outline-offset: -30px;
    }
    
    .card-container p {
        font-size: 2.1em;
        padding: 0 20px;
    }

}
