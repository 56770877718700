.footer-container {
    margin-top: auto;
    color: #fff;
    background-color: #373737;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.footer-wrapper {
    display: grid;
    grid-template-areas: 'footer-menu'
                         'footer-logo'
                         'footer-contact';
    margin: 20px 15px;
}

.footer-menu {
    grid-area: footer-menu;
    display: flex;
    justify-content: space-around;
    font-size: 1em;
}

.footer-menu-department h2 {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 12px;
}

.footer-menu-department ul li {
    font-weight: 300;
    margin-bottom: 12px;
    font-size: 1.05em;
}

.footer-menu-department ul li:last-child {
    margin-bottom: 0;
}

.footer-logo {
    grid-area: footer-logo;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.footer-logo img {
    width: 220px;
}

.footer-contact {
    grid-area: footer-contact;
    margin-top: 15px;
    padding: 0 5px;
}

.footer-contact h2 {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 5px;
}

.footer-contact div p {
    font-weight: 300;
    font-size: 0.9em;
    line-height: 1.5;
}

.footer-copyright {
    background-color: #000;
    border-bottom: 6px solid #2faf5b;
    padding: 7px 20px;
}

.footer-copyright p {
    font-weight: 300;
    font-size: 0.85em;
}

.footer-copyright p:first-child {
    margin-bottom: 2px;
}

.footer-copyright p:first-child span {
    font-weight: 400;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {

   .footer-wrapper {
        width: 80%;
        margin: 30px auto;
   }

   .footer-logo {
        margin-top: 25px;
   }

    .footer-logo img {
        width: 240px;
    }

    .footer-contact {
        margin-top: 20px;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .footer-wrapper {
        grid-template-areas: 'footer-menu footer-logo footer-contact';
        grid-template-columns: repeat(3, 1fr);
        width: 90%;
        margin: 40px auto;
    }

    .footer-menu-department h2 {
        font-size: 1em;
        margin-bottom: 7px;
    }

    .footer-menu-department ul li {
        font-size: 0.9em;
        margin-bottom: 7px;
    }

    .footer-menu-department ul li:hover {
        text-decoration: underline;
    }

    .footer-logo {
        margin: 0;
    }

    .footer-logo img {
        width: 280px;
        cursor: pointer;
    }

    .footer-contact {
        margin-top: 0;
    }

    .footer-contact h2 {
        font-size: 1em;
        margin-bottom: 7px;
    }

    /* .footer-contact div p {
        font-size: 1em;
    } */
    
    /* .footer-copyright p {
        font-size: 0.85em;
    } */
}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .footer-wrapper {
        width: 1300px;
    }

    .footer-menu-department h2 {
        margin-bottom: 9px;
    }

    .footer-menu-department ul li {
        margin-bottom: 9px;
    }

    .footer-logo img {
        width: 300px;
    }

    .footer-contact h2 {
        margin-bottom: 9px;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .footer-wrapper {
        width: 2300px;
        margin: 70px auto;
    }

    /* .footer-menu {
        outline: 1px solid red;
    } */

    .footer-menu-department h2 {
        font-size: 2.3em;
        margin-bottom: 12px;
    }

    .footer-menu-department ul li {
        margin-bottom: 12px;
        font-size: 2.1em;
    }

    .footer-logo img {
        width: 550px;
    }

    .footer-contact h2 {
        font-size: 2.3em;
        margin-bottom: 12px;
    }

    .footer-contact div p {
        font-size: 2em;
    }

    .footer-copyright {
        border-bottom: 10px solid #2faf5b;
        padding: 14px 20px;
    }
    
    .footer-copyright p {
        font-size: 1.9em;
    }

}