.copy-notification {
    display: none;
    position: fixed;
    width: 90%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.16);
    padding: 20px;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    text-align: center;
}

.display-copy-notification {
    display: block;
}

.copy-notification img {
    width: 64px;
    margin-bottom: 15px;
}

.copy-notification h2 {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 15px;
}

.copy-notification p {
    font-weight: 300;
    line-height: 1.5;
}

.receitas-content {
    padding: 20px 20px;
}

.receitas-summary {
    margin-bottom: 15px;
}

.receitas-summary p {
    font-weight: 300;
    text-indent: 20px;
    line-height: 1.5;
}

.receitas-summary p span {
    font-weight: 400;
}

.receitas-summary-image {
    text-align: center;
    margin: 20px 0;
}

.receitas-summary-image img {
    width: 64px;
}

#scroll-to-list {
    scroll-margin-top: 75px;
}

.receitas-summary-list {
    margin-top: 15px;
}

.receitas-button {
    display: block;
    font-size: 1em;
    padding: 10px 0;
}

.receitas-button-active {
    color: #2faf5b;
}

.receita {
    padding: 20px 0;
    margin-bottom: 10px;
}

.receita-empty {
    text-align: center;
}

.receita-empty p {
    font-weight: 300;
    line-height: 1.5;
}

.receita-content h2 {
    font-weight: 400;
    font-size: 1.1em;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
}

#scroll-to-receita {
    scroll-margin-top: 75px;
}

.receita-image {
    margin-bottom: 30px;
    text-align: center;
}

.receita-image img {
    width: 100%;
    outline: 2px solid #fff;
    outline-offset: -6px;
}

.receita-ingredients {
    margin-bottom: 35px;
}

.receita-ingredients h3 {
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
}

.receita-ingredients ul li {
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 2px;
}

.receita-ingredients ul li:last-child {
    margin-bottom: 0;
}

.receita-ingredients ul li span {
    width: 7px;
    height: 7px;
    background-color: #2faf5b;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -2px;
    margin-right: 7px;
}

.receita-cooking {
    margin-bottom: 35px;
}

.receita-cooking h3 {
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
}

.receita-cooking ol li {
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 2px;
}

.receita-cooking ol li:last-child {
    margin-bottom: 0;
}

.receita-cooking ol li span {
    font-weight: 400;
    color: #2faf5b;
}

.receita-time {
    margin-bottom: 50px;
}

.receita-time h3 {
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
}

.receita-time p {
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
}

/* .receita-buttons {
    outline: 1px solid red;
} */

.receita-buttons p {
    font-weight: 300;
    text-align: center;
    margin-bottom: 5px;
    line-height: 1.5;
}

.receita-buttons p span {
    font-weight: 400;
}

.receita-buttons-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.receita-buttons-main button {
    display: flex;
    padding: 5px;
}

.buttons-main-image {
    width: 56px;
}

.receita-buttons-main button:first-child {
    margin-right: 10px;
}

.receita-buttons-close {
    margin-top: 40px;
    text-align: center;
}

.receita-buttons-close button {
    font-size: 0.95em;
    padding: 5px 20px;
    text-decoration: underline;
}

/* ----- MEDIA QUERIES ----- */

/* Tablet - 768×1024 */
@media (min-width: 700px) {
    
    .copy-notification {
        width: 510px;
    }

    .receitas-content {
        padding: 20px 40px;
    }

    .receitas-summary-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

/* Desktop - 1366×768 */
@media (min-width: 1100px) {

    .copy-notification {
        padding: 30px;
    }
    
    .copy-notification h2 {
        font-size: 1em;
    }
    
    .copy-notification p {
        font-size: 0.95em;
        line-height: 1.6;
    }

    .receitas-content {
        padding: 40px 60px;
    }

    .receitas-summary {
        margin-bottom: 35px;
    }

    .receitas-summary p {
        font-size: 0.95em;
        line-height: 1.6;
        text-indent: 40px;
    }

    .receitas-summary p span {
        font-size: 0.95em;
    }

    .receitas-summary-image {
        margin: 30px 0;
    }

    .receitas-summary-list {
        margin-top: 25px;
    }

    #scroll-to-list {
        text-align: center;
        text-indent: 0;
    }

    .receitas-button {
        font-size: 0.95em;
        display: block;
        padding: 0;
        margin: 10px 0;
    }
    
    .receitas-button:last-child {
        margin-bottom: 0;
    }

    .receitas-button:hover {
        text-decoration: underline;
    }

    .receita-empty p {
        font-size: 0.95em;
    }

    .receita-content {
        width: 75%;
        margin: 0 auto;
    }

    .receita-content h2 {
        margin-bottom: 15px;
        font-size: 1.05em;
    }

    #scroll-to-receita {
        scroll-margin-top: 85px;
    }

    .receita-image {
        margin-bottom: 35px;
    }

    .receita-ingredients h3,
    .receita-cooking h3,
    .receita-time h3 {
        margin-bottom: 10px;
        font-size: 1.05em;
    }

    .receita-ingredients ul,
    .receita-cooking ol {
        padding: 0 10px;
    }

    .receita-ingredients ul li,
    .receita-cooking ol li {
        font-size: 0.95em;
        line-height: 1.6;
    }

    .receita-time p {
        font-size: 0.95em;
        line-height: 1.6;
    }

    .receita-buttons p {
        font-size: 0.95em;
        margin-bottom: 15px;
    }

    .receita-buttons p span {
        font-size: 0.95em;
    }

    .receita-buttons-main button:hover {
        opacity: 0.9;
    }

    .receita-buttons-main button:hover {
        outline-offset: 0px;
    }

    .receita-buttons-close button {
        font-size: 0.9em;
    }

    .receita-buttons-close button:hover {
        text-decoration: none;
    }

}

/* Desktop - 1920×1080 */
@media (min-width: 1700px) {

    .receitas-content {
        padding: 50px 100px;
    }

    .receitas-summary p {
        text-indent: 50px;
    }

    .receitas-summary-image img {
        width: 74px;
    }

    .receita-content {
        width: 60%;
    }

}

/* Desktop - 3840×2160 */
@media (min-width: 2800px) {

    .copy-notification {
        width: 950px;
        padding: 50px;
    }
    
    .copy-notification img {
        width: 110px;
        margin-bottom: 35px;
    }

    .copy-notification h2 {
        font-size: 2.3em;
        margin-bottom: 30px;
    }

    .copy-notification p {
        font-size: 2.1em;
    }

    .receitas-content {
        padding: 80px 120px; 
    }

    .receitas-summary {
        margin-bottom: 60px;
    }

    .receitas-summary p {
        font-size: 2.1em;
        text-indent: 80px;
    }
    
    .receitas-summary-image {
        margin: 50px 0;
    }

    .receitas-summary-image img {
        width: 128px;
    }

    #scroll-to-list {
        scroll-margin-top: 135px;
    }

    .receitas-summary-list {
        margin-top: 35px;
    }

    .receitas-button {
        font-size: 2.1em;
        margin: 15px 0;
    }

    .receita-empty {
        font-size: 2.1em;
    }

    .receita-content h2 {
        font-size: 2.3em;
    }

    #scroll-to-receita {
        scroll-margin-top: 135px;
    }

    .receita-image img {
        outline: 4px solid #fff;
        outline-offset: -20px;
    }

    .receita-ingredients h3,
    .receita-cooking h3,
    .receita-time h3 {
        font-size: 2.15em;
    }

    .receita-ingredients ul li,
    .receita-cooking ol li {
        font-size: 2.1em;
    }

    .receita-ingredients ul li span {
        width: 16px;
        height: 16px;
        top: -4px;
        margin-right: 15px;
    }

    .receita-time p {
        font-size: 2.1em;
    }

    .receita-buttons p {
        font-size: 2.1em;
        margin-bottom: 35px;
    }

    .buttons-main-image {
        width: 110px;
    }

    .receita-buttons-main button:first-child {
        margin-right: 20px;
    }

    .receita-buttons-close {
        margin-top: 70px;
    }

    .receita-buttons-close button {
        font-size: 2.1em;
    }

}